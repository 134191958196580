<template>
  <div class="container">
    <Navbar navTitle="DEALER ADMIN"></Navbar>
    <main>
      <Nav></Nav>
      <div></div>
    </main>
    <Footer></Footer>
  </div>
</template>

<script>
import Footer from '@/components/Footer/Footer'
import Nav from './Nav.vue'

export default {
  components: {
    Footer,
    Nav
  },
  data() {
    return {
      stepIndex: 0,
    }
  }
  
}
</script>
<style>
html, body, #app {
  height: 100%
}
</style>
<style lang="less" scoped>

.container {
  min-height: 100%;
  background: #815611;
}
.stepBox {
  padding: 20px;
}
.formBox {
  background: #fff;
  border-radius: 10px;
  margin-bottom: 20px;
  padding: 10px 0;
  overflow: hidden;
  box-shadow: 0 0px 5px #333;
}
.stepContent {
  padding-bottom: 30px;
}

</style>
